<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh" width="90%">
    <el-row>
      <el-col :span="12">
        <el-card class="margin-xs">
          <template #header>
            <div class="card-header">
              <span>基本信息</span>
            </div>
          </template>
          <table class="table-detail">
            <tr>
              <th>建议项目</th>
              <td colspan="3">{{ dataInfo.adviceTitle }}</td>
            </tr>
            <tr>
              <th>建议类型</th>
              <td>{{ dataInfo.adviceType }}</td>
              <th>建议分类</th>
              <td>{{ dataInfo.adviceCategory }}</td>
            </tr>
            <tr>
              <th>建议人</th>
              <td>{{ dataInfo.createUserName }}</td>
              <th>提出部门</th>
              <td>{{ dataInfo.createDepartName }}</td>
            </tr>
            <tr>
              <th>提出时间</th>
              <td>{{ dataInfo.submitTime }}</td>
              <th>建议状态</th>
              <td class="text-bold">
                <span v-if="dataInfo.adviceStatus == 0" class="text-grey">未提交</span>
                <span v-else-if="dataInfo.adviceStatus == 1" class="text-blue">已提交</span>
                <span v-else-if="dataInfo.adviceStatus == 10" class="text-green">已入围</span>
                <span v-else-if="dataInfo.adviceStatus == 9" class="text-red">无效建议</span>
              </td>
            </tr>
          </table>
        </el-card>
        <el-card class="margin-xs">
          <template #header>
            <div class="card-header">
              <span>存在问题</span>
            </div>
          </template>
          <div class="padding-xs">{{ dataInfo.adviceContent }}</div>
          <div>
            <el-col :span="12" v-for="(item, index) in dataInfo.fileAList" :key="index">
              <p class="text-cut flex align-center padding-mini fileitem">
                <img :src="item.iconUrl" class="fileIcon" /><a @click="$site.view_file(item)">{{ item.name }}</a>
              </p>
            </el-col>
          </div>
        </el-card>
        <el-card class="margin-xs">
          <template #header>
            <div class="card-header">
              <span>改进措施</span>
            </div>
          </template>
          <div class="padding-xs">{{ dataInfo.adviceMethod }}</div>
          <div>
            <el-col :span="12" v-for="(item, index) in dataInfo.fileBList" :key="index">
              <p class="text-cut flex align-center padding-mini fileitem">
                <img :src="item.iconUrl" class="fileIcon" /><a @click="$site.view_file(item)">{{ item.name }}</a>
              </p>
            </el-col>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="margin-xs">
          <template #header>
            <div class="card-header">
              <span>审核信息</span>
            </div>
          </template>
          <table class="table-detail">
            <tr>
              <th>审核人</th>
              <td>{{ dataInfo.verifyUserName }}</td>
              <th>审核部门</th>
              <td>{{ dataInfo.verifyDepartName }}</td>
            </tr>
            <tr>
              <th>审核时间</th>
              <td>{{ dataInfo.verifyTime }}</td>
              <th>审核结果</th>
              <td class="text-bold">
                <p v-if="dataInfo.adviceStatus == 1">
                  已提交-
                  <span v-if="dataInfo.verifyHistoryIds" class="text-cyan">已转移</span>
                  <span v-else class="text-gray">未审核</span>
                </p>
                <p v-else-if="dataInfo.adviceStatus == 9" class="text-red">无效建议</p>
                <p v-else-if="dataInfo.adviceStatus == 10" class="text-green">
                  已入围-
                  <span v-if="dataInfo.effectStatus == 1" class="text-cyan">实施中</span>
                  <span v-else-if="dataInfo.effectStatus == 8" class="text-red">无法实施</span>
                  <span v-else-if="dataInfo.effectStatus == 9" class="text-red">暂缓实施</span>
                  <span v-else-if="dataInfo.effectStatus == 2" class="text-green">实施完成</span>
                </p>
              </td>
            </tr>
            <tr>
              <th>审核说明</th>
              <td colspan="3">{{ dataInfo.unableDesc || dataInfo.transferDesc }}</td>
            </tr>
          </table>
        </el-card>

        <el-card class="margin-xs" v-if="dataInfo.effectStatus == 1 || dataInfo.effectStatus == 2">
          <template #header>
            <div class="card-header">
              <span>实施信息</span>
            </div>
          </template>
          <table class="table-detail">
            <tr>
              <th>要求时间</th>
              <td>{{ dataInfo.effectRequestDate }}</td>
              <th>实施要求</th>
              <td>{{ dataInfo.effectRequestDesc }}</td>
            </tr>
            <tr>
              <th>实施人</th>
              <td>{{ dataInfo.verifyTime }}</td>
              <th>实施部门</th>
              <td>{{ dataInfo.verifyDepartName }}</td>
            </tr>
            <tr>
              <th>实施时间</th>
              <td>{{ dataInfo.submitTime }}</td>
              <th>实施状态</th>
              <td class="text-bold">
                <span v-if="dataInfo.effectStatus == 0" class="text-gray">未实施</span>
                <span v-else-if="dataInfo.effectStatus == 1" class="text-blue">实施中</span>
                <span v-else-if="dataInfo.effectStatus == 2" class="text-green">实施完成</span>
              </td>
            </tr>
            <tr>
              <th>实施说明</th>
              <td colspan="3">{{ dataInfo.unableDesc || dataInfo.transferDesc }}</td>
            </tr>
            <tr>
              <th>实施图片</th>
              <td colspan="3">
                <el-col v-for="(item, index) in dataInfo.effectImgList" :key="index" :span="6">
                  <el-card class="padding0 margin-mini" style="line-height:0px;">
                    <el-image style="width: 100%; height: 80px" :src="item" @click="$site.view_file(item)"> </el-image>
                  </el-card>
                </el-col>
              </td>
            </tr>
            <tr v-if="dataInfo.fileCList && dataInfo.fileCList.length !== 0">
              <th>相关文件</th>
              <td colspan="3">
                <el-col :span="12" v-for="(item, index) in dataInfo.fileCList" :key="index">
                  <p class="text-cut flex align-center padding-mini fileitem">
                    <img :src="item.iconUrl" class="fileIcon" /><a>{{ item.name }}</a>
                  </p>
                </el-col>
              </td>
            </tr>
          </table>
        </el-card>
        <el-card class="margin-xs">
          <template #header>
            <div class="card-header">
              <span>建议评价</span>
            </div>
          </template>
          <el-form ref="dataInfo" :model="dataInfo" label-width="120px">
            <el-form-item label="评价等级" prop="evaluateLevel">
              <el-radio-group v-model="dataInfo.evaluateLevel" @change="levelChange()">
                <el-radio-button v-for="(item, index) in levelList" :key="index" :label="item.levelName">{{ item.levelName }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="奖励金额" prop="costSaving">
              <el-col :span="12">
                <el-input v-model="dataInfo.evaluateMoney" type="number" autocomplete="off" style="width:200px">
                <span slot="suffix">元</span>
              </el-input>
              </el-col>
              <el-col class="line" :span="3">
                <label for="costSaving" class="el-form-item__label">节约成本</label>
                </el-col>
              <el-col :span="8">
                <el-input v-model="dataInfo.costSaving" type="number" autocomplete="off" style="width:200px">
                <span slot="suffix">元</span>
              </el-input>
              </el-col>

            </el-form-item>
            <el-form-item label="评价说明" prop="evaluateDesc">
              <el-input type="textarea" v-model="dataInfo.evaluateDesc" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="save" type="primary">确认评价</el-button>
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      editType: '',
      activeName: 'base',
      levelList: null,
      levelDic: {}
    }
  },
  created() {
    this.getLevelList()
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    getLevelList() {
      this.$lgh.get('api/enterprise/advice/evaluatelevel', ['enterpriseCode'], false).then(res => {
        this.levelList = res
        res.forEach(a => {
          this.levelDic[a.levelName] = a
        })
      })
    },
    levelChange() {
      this.dataInfo.evaluateMoney = this.levelDic[this.dataInfo.evaluateLevel].money
    },
    save() {
      this.$lgh.msgConfirm('确认评价?', () => {
        this.$lgh.post('api/enterprise/advice/evaluate', this.dataInfo).then(res => {
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        })
      })
    }
  }
}
</script>
